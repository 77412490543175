// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Environment, UserRole } from './environment.model';

export const environment: Environment = {
  production: true,
  buyUrl: '',
  SCARF_ANALYTICS: false,
  adminRoot: '/app',
  apiUrl: '',
  defaultMenuType: 'menu-default',
  subHiddenBreakpoint: 768,
  menuHiddenBreakpoint: 768,
  themeColorStorageKey: 'vien-themecolor',
  isMultiColorActive: false,
  defaultColor: 'light.blueyale',
  isDarkSwitchActive: false,
  defaultDirection: 'ltr',
  themeRadiusStorageKey: 'vien-themeradius',
  isAuthGuardActive: true,
  defaultRole: UserRole.Admin,
  siteKey: '6LcvoUgUAAAAAJJbhcXvLn3KgG-pyULLusaU4mL1',
};