<div class="bg-light col-11 shadow border p-5 mx-auto mb-2">

    <div class="row">
        <div class="col-12 px-4">
            <p>
                Ce site n’accepte aucune forme de publicité, ni ne reçoit de fonds publicitaires.
            </p>
        </div>
    </div>

    <div class="row">
        <div class="col-12 px-4">
            <h2> Protection des données personnelles </h2>
        </div>
    </div>

    <div class="row">
        <div class="col-12 px-4">
            <p>
                www.mutuellenickel.nc a fait l’objet d’une déclaration des traitements automatisés auprès de la Commission Nationale de l’Informatique et des Libertés (CNIL), en application des dispositions de la loi 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés.
            </p>
            <p>
                Les informations collectées sont réservées à l’usage de la Mutuelle du Nickel et ne seront aucunement vendues ou échangées à des tiers.<br>
                Dans le cadre de l’évolution de son offre de services, la Mutuelle du Nickel peut être amenée à demander aux internautes des informations personnelles, afin de leur transmettre des informations relatives à ces offres, si l’internaute en a fait la demande explicite.
            </p>
        </div>
    </div>

    <div class="row">
        <div class="col-12 px-4">
            <h2> Propriété intellectuelle </h2>
        </div>
    </div>

    <div class="row">
        <div class="col-12 px-4">
            <p>
                Le site mutuellenickel.nc relève de la réglementation applicable aux droits d’auteur et à la propriété intellectuelle.<br>
                Pour tous les textes et œuvres présentés sur www.mutuellenickel.nc , tous droits d’auteur des œuvres sont réservés.<br>
                Sauf autorisation formelle écrite préalable, la reproduction ainsi que toute utilisation des œuvres, autres que la consultation individuelle et privée, sont interdites.
            </p>
            <p>
                Toute demande d’autorisation pour quelque utilisation que ce soit doit être adressée à :<br>
                Mutuelle du Nickel<br>
                BP 776 – 98845  NOUMEA CEDEX<br>
                NOUVELLE-CALEDONIE<br>
                E-mail : secretariat@mdn.nc</p>
        </div>
    </div>

    <div class="row">
        <div class="col-12 px-4">
            <h2> Responsabilités </h2>
        </div>
    </div>

    <div class="row">
        <div class="col-12 px-4">
            <p>
                Les informations diffusées sur www.mutuellenickel.nc n’engagent pas la responsabilité de la Mutuelle du Nickel.
            </p>
            <p>
                Nous nous efforçons de tenir ces informations à jour et exactes. Néanmoins, nous ne pouvons les garantir.<br>
                Merci de nous signaler toute erreur ou omission en utilisant la rubrique du site « Nous contacter ».
            </p>
            <p>
                Les informations médicales ne sont pas destinées à remplacer la consultation d’un médecin. Elles ont au contraire vocation à l’encourager en aidant sa préparation. Elles ne peuvent en aucun cas servir à l’établissement d’un diagnostic ou d’un traitement médical.
            </p>
        </div>
    </div>

    <div class="row">
        <div class="col-12 px-4">
            <p>
                Dans le cadre de la rubrique « Nous contacter » destinée à permettre une demande de renseignements par e-mail, les réponses fournies par la Mutuelle du Nickel ne constituent en aucun cas un engagement contractuel car elles sont données à partir des seuls éléments fournis par l’internaute.
                La Mutuelle du Nickel ne possédant pas l’ensemble des éléments pour établir une réponse définitive, ne peut donc être tenue pour responsable des suites données par l’internaute.
            </p>
        </div>
    </div>

    <div class="row">
        <div class="col-12 px-4">
            <h2> Liens hypertextes </h2>
        </div>
    </div>

    <div class="row">
        <div class="col-12 px-4">
            <p>
                www.mutuellenickel.nc propose des liens hypertextes vers des sites web édités et/ou gérés par des tiers.
                Nous vérifions la qualité des sites que nous recommandons, néanmoins nous ne saurions être responsables, contrôler ou garantir l’actualité et l’exactitude des informations diffusées sur les sites des sociétés, organismes ou personne privée vers lesquels nous avons établi des liens.
                Les marques citées appartiennent à leurs propriétaires respectifs.</p>
        </div>
    </div>
</div>
