<div class="" id="top-page">
  <!-- <app-color-switcher *ngIf="isMultiColorActive"></app-color-switcher> -->
  <router-outlet></router-outlet>
</div>
<!-----------------modal de bienvenue---------------->
<div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal" class="modal fade modalWelcome lg" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name">
  <div class="modal-dialog">
    <div class="modal-content modcontent">
      <div class="modal-header modheader">
        <div class="titleAndCloseBtn">
          <h1 id="dialog-auto-name" class="modal-title pull-left">Bienvenue sur votre espace personnel</h1>
          <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="hideModal()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
          </button>
        </div>
        <div class="row" [ngClass]="{'pr-5 pl-5': getCards()=='5','pl-2 pr-2': getCards()=='4'}">
          <p class="text-center font-weight-normal text-white infoWelcome"> Vous y trouverez vos
            <span class="font-weight-bold">'Informations'             
            </span> avec vos coordonnées modifiables en direct en cas de changements de situation, des indications sur votre <span class="font-weight-bold">'Adhésion'</span>
            et celles de vos ayants-droits, et vos états de 
            <span class="font-weight-bold">'Remboursement'
            </span> en temps réél.<br> Bonne navigation !</p>
        </div>
         </div>
      <div class="modal-body mbody">
        <h2 class="text-center"> Accéder à une rubrique ci-dessous</h2>
        <div class="modalContainer">
            <div [ngClass]="{'display3cards': getCards()=='5','display2cards': getCards()=='4'}" class="row">
              <!--mes infos card-->
              <div class="col cardItem">
                <a class="linkCards" (click)="hideModal()">
                  <div class="card card-mod">
                    <div class="card-body">
                      <h2 class="card-title modalWelcome__title "><i ng-reflect-ng-class="iconsminds-id-card" class="iconsminds-id-card"></i>Mes informations</h2>
                    </div>
                  </div>
                </a>
                </div>
              <!--mon adhesion cards-->
                <div class="col cardItem" (mouseenter)="showSubMenuCardAdhesion=true" (mouseleave)="showSubMenuCardAdhesion=false">
                <a class="linkCards" ng-reflect-router-link="/adherent/app/mon-adhesion" href="/adherent/app/mon-adhesion" *ngIf="!showSubMenuCardAdhesion">
                    <div class="card card-mod">
                      <div class="card-body ">
                        <h2 class="card-title modalWelcome__title"><i  ng-reflect-ng-class="iconsminds-add-user" class="iconsminds-add-user"></i>Mon adhésion</h2>
                      </div>
                    </div>
                </a>
      
                <a class="linkCards" ng-reflect-router-link="/adherent/app/mon-adhesion" href="/adherent/app/mon-adhesion" *ngIf="showSubMenuCardAdhesion">
                  <div class="card">
                    <div class="card-body ">
                      <h2 class="card-title modalWelcome__title"><i  ng-reflect-ng-class="iconsminds-add-user" class="iconsminds-add-user"></i>Mon adhésion</h2>
                    </div>
                  </div>
                </a>
              </div>
              <!--mes cotisations cards-->
              <div class="col cardItem" *ngIf="isRetraite()" (mouseenter)="showSubMenuCardCotisation=true" (mouseleave)="showSubMenuCardCotisation=false">
                <a class="linkCards" ng-reflect-router-link="/adherent/app/cotisations" href="/adherent/app/cotisations" *ngIf="!showSubMenuCardCotisation">
                  <div class="card card-mod">
                    <div class="card-body ">
                      <h2 class="card-title modalWelcome__title"><i  ng-reflect-ng-class="iconsminds-wallet" class="iconsminds-wallet"></i>Mes cotisations</h2>
                    </div>
                  </div>
                </a>

                <a class="linkCards" ng-reflect-router-link="/adherent/app/cotisations" href="/adherent/app/cotisations" *ngIf="showSubMenuCardCotisation">
                  <div class="card">
                    <div class="card-body col" style="font-size: 16px;">
                      <a class="col card-title modalWelcome__title" routerlinkactive="active" ng-reflect-router-link-active="active" ng-reflect-router-link="/adherent/app/cotisations/modalites-paiement" href="/adherent/app/cotisations/modalites-paiement"><i  ng-reflect-ng-class="iconsminds-speach-bubble-asking" class="iconsminds-speach-bubble-asking"></i><span class="d-inline-block mb-2 subTitle">Modalités de paiement</span></a><br>
                      <a class="col card-title modalWelcome__title" routerlinkactive="active" ng-reflect-router-link-active="active" ng-reflect-router-link="/adherent/app/cotisations/appels-cotisation" href="/adherent/app/cotisations/appels-cotisation"><i  ng-reflect-ng-class="iconsminds-financial" class="iconsminds-financial"></i> <span  class="d-inline-block mb-2 subTitle">Appels de cotisation</span></a><br>
                      <a class="col card-title modalWelcome__title" routerlinkactive="active" ng-reflect-router-link-active="active" ng-reflect-router-link="/adherent/app/cotisations/situation-compte" href="/adherent/app/cotisations/situation-compte"><i  ng-reflect-ng-class="iconsminds-calendar-4" class="iconsminds-calendar-4"></i> <span  class="d-inline-block mb-2 subTitle">Situation de compte</span></a><br>
                      <a class="col card-title modalWelcome__title" routerlinkactive="active" ng-reflect-router-link-active="active" ng-reflect-router-link="/adherent/app/cotisations/deduction-fiscale" href="/adherent/app/cotisations/deduction-fiscale"><i  ng-reflect-ng-class="iconsminds-calendar-4" class="iconsminds-calendar-4"></i> <span  class="d-inline-blockmb-2 subTitle">Déduction fiscale</span></a><br>
                    </div>
                  </div>
                </a>
              </div>
                <!--bénéficiaires cards-->
                      <div class="col cardItem" (mouseenter)="showSubMenuCardRBenef=true" (mouseleave)="showSubMenuCardBenef=false">
                        <a class="linkCards" ng-reflect-router-link="/adherent/app/droits-et-beneficiaires" href="/adherent/app/droits-et-beneficiaires" *ngIf="!showSubMenuCardBenef">
                          <div class="card card-mod">
                            <div class="card-body ">
                              <h2 class="card-title modalWelcome__title"><i  ng-reflect-ng-class="iconsminds-wallet" class="iconsminds-wallet"></i>Mes Bénéficiaires 
                                <br>(Leurs droits, forfaits, attestations)</h2>
                            </div>
                          </div>
                        </a>
        
                        <a class="linkCards" ng-reflect-router-link="/adherent/app/droits-et-beneficiaires" href="/adherent/app/droits-et-beneficiaires" *ngIf="showSubMenuCardBenef">
                          <div class="card">
                            <div class="card-body col" style="font-size: 16px;">
                              <a class="col card-title modalWelcome__title" routerlinkactive="active" ng-reflect-router-link-active="active" ng-reflect-router-link="/adherent/app/remboursements/modalites-remboursement" href="/adherent/app/remboursements/modalites-remboursement"><i  ng-reflect-ng-class="iconsminds-speach-bubble-asking" class="iconsminds-speach-bubble-asking"></i><span class="d-inline-block">Modalités de remboursement</span></a><br><br>
                              <a class="col card-title modalWelcome__title" routerlinkactive="active" ng-reflect-router-link-active="active" ng-reflect-router-link="/adherent/app/remboursements/remboursements" href="/adherent/app/remboursements/remboursements"><i  ng-reflect-ng-class="iconsminds-financial" class="iconsminds-financial"></i> <span  class="d-inline-block">Mes remboursements</span></a><br><br>
                              <a class="col card-title modalWelcome__title" routerlinkactive="active" ng-reflect-router-link-active="active" ng-reflect-router-link="/adherent/app/remboursements/mes-telechargements" href="/adherent/app/remboursements/mes-telechargements"><i  ng-reflect-ng-class="iconsminds-calendar-4" class="iconsminds-calendar-4"></i> <span  class="d-inline-block">Mes téléchargements</span></a><br>
                            </div>
                          </div>
                        </a>
                  </div>
                <!--remboursements cards-->
                <div class="col cardItem" (mouseenter)="showSubMenuCardRemboursement=true" (mouseleave)="showSubMenuCardRemboursement=false">
                  <a class="linkCards" ng-reflect-router-link="/adherent/app/remboursements/modalites-remboursement" href="/adherent/app/remboursements/modalites-remboursement" *ngIf="!showSubMenuCardRemboursement">
                    <div class="card card-mod">
                      <div class="card-body">
                        <h2 class="card-title modalWelcome__title"><i  ng-reflect-ng-class="iconsminds-wallet" class="iconsminds-wallet"></i>Mes remboursements</h2>
                      </div>
                    </div>
                  </a>
                  <a class="linkCards" ng-reflect-router-link="/adherent/app/remboursements/modalites-remboursement" href="/adherent/app/remboursements/modalites-remboursement" *ngIf="showSubMenuCardRemboursement">
                    <div class="card">
                      <div class="card-body col" style="font-size: 16px;">
                        <a class="col card-title modalWelcome__title" routerlinkactive="active" ng-reflect-router-link-active="active" ng-reflect-router-link="/adherent/app/remboursements/modalites-remboursement" href="/adherent/app/remboursements/modalites-remboursement"><i  ng-reflect-ng-class="iconsminds-speach-bubble-asking" class="iconsminds-speach-bubble-asking"></i><span class="d-inline-block subTitle">Modalités de remboursement</span></a><br><br>
                        <a class="col card-title modalWelcome__title" routerlinkactive="active" ng-reflect-router-link-active="active" ng-reflect-router-link="/adherent/app/remboursements/remboursements" href="/adherent/app/remboursements/remboursements"><i  ng-reflect-ng-class="iconsminds-financial" class="iconsminds-financial"></i> <span  class="d-inline-block subTitle">Mes remboursements</span></a><br><br>
                        <a class="col card-title modalWelcome__title" routerlinkactive="active" ng-reflect-router-link-active="active" ng-reflect-router-link="/adherent/app/remboursements/mes-telechargements" href="/adherent/app/remboursements/mes-telechargements"><i  ng-reflect-ng-class="iconsminds-calendar-4" class="iconsminds-calendar-4"></i> <span  class="d-inline-block subTitle" >Mes téléchargements</span></a><br>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
      </div>
    </div>
  </div>
</div>
<simple-notifications></simple-notifications>
