<div id="root">
  <div class="fixed-background">
    <main class="ml-0">
      <div class="container" style="height:100vh;">
        <div class="row h-100">
          <div class="col-12 col-md-10 mx-auto my-auto">
            <div class="card auth-card">
              <div class="position-relative image-side bg-primary align-items-center d-flex">
                <div style="padding: 0 0 0 1rem;">
                  <p class=" text-white h2">MAGIC IS IN THE DETAILS</p>
                  <p class="white mb-0">Yes, it is indeed!</p>
                </div>
              </div>
              <div class="form-side d-flex flex-column align-items-center">
                <app-logo logoWidth="25rem" logoHeight="10rem"></app-logo>
                <p class="display-1 font-weight-bold mb-0">401</p>
                <h6 class="mb-0">{{ "unauthorized.title" | translate }}</h6>
                <p class="text-muted text-small mb-5">
                  {{ "unauthorized.detail" | translate }}
                </p>
                <a href="https://adherent.mdn.nc/" class="btn btn-primary btn-lg btn-shadow" type="submit">
                  {{ "pages.go-back-home" | translate }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</div>
