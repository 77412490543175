import {
    CanActivate,
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanActivateChild,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './keycloak.auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {

    constructor(private authService: AuthService, private router: Router) { }

    async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const currentUrl = state.url.includes('adherent') ? 'adherent' : state.url.includes('employeur') ? 'employeur' : '';
        const currentUser = await this.authService.getLoggerUser();
        if (currentUser) {
            if (route.data && route.data.roles) {
                if (route.data.roles.includes(currentUser.roles[0])) {
                    return true;
                } else {
                    let infospersoError = null;
                    if (localStorage.getItem('infospersoError')) {
                        infospersoError = JSON.parse(localStorage.getItem('infospersoError'));
                    }
                    if (infospersoError?.error?.Libelle_retour) {
                        this.router.navigate([`/${currentUrl}/error`], { queryParams: { msg: `Erreur lors de la connexion, veuillez contacter la Mutuelle du Nickel`, logout: true } });
                    } else {
                        this.router.navigate([`/${currentUrl}/error`], { queryParams: { logout: true } });
                    }
                    return false;
                }
            } else {
                return true;
            }
        } else {
            this.router.navigate([`/${currentUrl}/error`], { queryParams: { logout: true } });
            return false;
        }
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const currentUrl = state.url.includes('adherent') ? 'adherent' : state.url.includes('employeur') ? 'employeur' : '';
        const currentUser = await this.authService.getLoggerUser();
        if (currentUser) {
            if (route.data && route.data.roles) {
                if (route.data.roles.includes(currentUser.roles[0])) {
                    return true;
                } else {
                    let infospersoError = null;
                    if (localStorage.getItem('infospersoError')) {
                        infospersoError = JSON.parse(localStorage.getItem('infospersoError'));
                    }
                    if (infospersoError?.error?.Libelle_retour) {
                        this.router.navigate([`/${currentUrl}/error`], { queryParams: { msg: `Erreur lors de la connexion, veuillez contacter la Mutuelle du Nickel`, logout: true } });
                    } else {
                        this.router.navigate([`/${currentUrl}/error`], { queryParams: { logout: true } });
                    }
                    return false;
                }
            } else {
                return true;
            }
        } else {
            this.router.navigate([`/${currentUrl}/error`], { queryParams: {...route.queryParams, logout: true} });
            return false;
        }
    }
}
