import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Article, ListeBenefEmployeur, ListeBenefResponse, MailEntity, MemberType, PaginatedArticlesResponse, Response } from '../../app/shared/models/models';
import * as _ from 'lodash';
import { ENDPOINT } from '../constants/endpoints';
import * as moment from 'moment';

const OK_RESPONSE = new Response();
const MDN_URL = `https://adherent.mdn.nc/`;

@Injectable({ providedIn: 'root' })
export class ApiService {
  constructor(private http: HttpClient) {}

  get = (api: string, url: string): Observable<any> => this.http.get(`api/${api}/ws/${url}`);
  getParams = (api: string, url: string, params: HttpParams): Observable<any> => this.http.get(`api/${api}/ws/${url}`, { params });
  put = (api: string, url: string, body: any): Observable<any> => this.http.put(`api/${api}/ws/${url}`, body);
  post = (api: string, url: string, body: any): Observable<any> => this.http.post(`api/${api}/ws/${url}`, body);
  delete = (api: string, url: string): Observable<any> => this.http.delete(`api/${api}/ws/${url}`);

  sendMail = (body: MailEntity): Observable<any> => this.http.post(`mail/mail/send`, body);

  /********************************************** LISTE BENEF EMP *********************************************/

  getListeBenefEmp(): Observable<{ all: ListeBenefResponse, adhAyd: ListeBenefResponse}> {
    return this.http.get<ListeBenefResponse>(`api/mdne/ws/${ENDPOINT.listebenefemp.path}`).pipe(
      map((res: ListeBenefResponse) => {
        const listeBenefEmp: ListeBenefEmployeur[] = res.Liste_benef as ListeBenefEmployeur[]; // all
        const listeBenefEmpAdh = listeBenefEmp.filter((benef) => benef.Type_benef === 'ADHERENT'); // only ADHERENT
        const listeBenefEmpAyd = listeBenefEmp.filter((benef) => benef.Type_benef === 'AYANT_DROIT'); // only AYANT_DROIT
        const listeBenefEmpAdhAyd = [...listeBenefEmpAdh]; // only ADHERENT
        listeBenefEmpAdhAyd.forEach((benefAdh) => {
          benefAdh.ayd = listeBenefEmpAyd
            .filter((benefAyd) => benefAyd.Num_famille === benefAdh.Num_famille)
            .sort((a, b) => (moment(a.Date_naissance).isBefore(moment(b.Date_naissance)) ? -1 : 1));
        }); // ADHERENT - AYANT_DROIT
        const listeBenefEmpResponse: ListeBenefResponse = { ...res, Liste_benef: [...listeBenefEmp.sort((a, b) => a.Num_famille - b.Num_famille)] };
        const listeBenefEmpAdhAydResponse: ListeBenefResponse = { ...res, Liste_benef: [...listeBenefEmpAdh.sort((a, b) => a.Num_famille - b.Num_famille)] };
        return { all: listeBenefEmpResponse, adhAyd: listeBenefEmpAdhAydResponse };
      }),
      catchError((errorRes) => throwError(errorRes))
    );
  }

  /************************************************* A SAVOIR *************************************************/

  getListeCategories = (): Observable<MemberType[]> => this.http.get<MemberType[]>(`${MDN_URL}member_type`);

  getArticles(id: string, page: number, pageSize: number): Observable<PaginatedArticlesResponse> {
    const params = new HttpParams().append('pageSize', JSON.stringify(pageSize)).append('page', JSON.stringify(page));
    return this.http.get<Article[]>(`${MDN_URL}actualites/${id}`, { params }).pipe(
      map((res: Article[]) => {
        const filtered: Article[] = [];
        let fieldMemberType = '';
        if (id === '463') { fieldMemberType = 'Adhérent'; }
        if (id === '464') { fieldMemberType = 'Employeur'; }
        res.forEach((article: Article) => {
          if (article.field_member_type.includes(fieldMemberType)) { filtered.push(article); }
        });
        const response: PaginatedArticlesResponse = {
          ...OK_RESPONSE,
          page,
          next_page: null,
          page_size: pageSize,
          prev_page: null,
          total_page: 1,
          total_record: res.length,
          records: filtered,
        };
        if (filtered.length > pageSize) {
          const chunkArray = this.chunkArray(filtered, pageSize);
          response.next_page = chunkArray[page] ? page + 1 : null;
          response.prev_page = chunkArray[page - 2] ? page - 1 : null;
          response.total_page = chunkArray.length;
          response.records = chunkArray[page - 1];
        }
        return response;
      }),
      catchError((errorRes) => throwError(errorRes))
    );
  }

  chunkArray(inputArray: Article[], pageSize: number): Array<Article[]> {
    const perChunk = pageSize;
    const result = inputArray.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk);
      if (!resultArray[chunkIndex]) { resultArray[chunkIndex] = []; }
      resultArray[chunkIndex].push(item);
      return resultArray;
    }, []);
    return result;
  }
}