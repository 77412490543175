import { Injectable, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';

import * as categories from '../../assets/json/categories.json';
import * as articles from '../../assets/json/articles.json';
import * as infopersoadherent from '../../assets/json/infopersoadherent.json';
import * as infopersoemployeur from '../../assets/json/infopersoemployeur.json';
import * as listebenef from '../../assets/json/listebenef.json';
import * as infospaiements from '../../assets/json/infospaiements.json';
import * as historiquecotis from '../../assets/json/historiquecotis.json';

import {
  ListeBenefResponse,
  InfosEmployeurResponse,
  InfosAdherentResponse,
  Article,
  MemberType,
  Response,
  InfoPaieCotisResponse,
  HistoMvtCotisResponse,
} from '../shared/models/models';

import { ENDPOINT } from '../constants/endpoints';

import { Observable, of } from 'rxjs';
import * as _ from 'lodash';

const MDN_URL = `https://mdn.nc/export/`;

@Injectable()
export class MockHttpInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  okResponse(body: any): Observable<HttpResponse<any>> {
    return of(new HttpResponse({ status: 200, body }));
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.handleRequests(request, next);
  }

  handleRequests(request: HttpRequest<any>, next: HttpHandler): any {
    const { url, method } = request;
    // A SAVOIR - MEMBER TYPE
    if (_.includes(url, `${MDN_URL}member_type`) && method === 'GET') {
      console.log('Loaded from categories.json');
      return of(
        new HttpResponse({
          status: 200,
          body: (categories as any).default as MemberType[],
        })
      );
    }

    // A SAVOIR - ARTICLES
    if (_.includes(url, `${MDN_URL}actualites/`) && method === 'GET') {
      console.log('Loaded from articles.json');
      return of(
        new HttpResponse({
          status: 200,
          body: (articles as any).default as Article[],
        })
      );
    }
    return next.handle(request);
  }
}

// if (url.endsWith('/employes') && method === 'GET') {
//   request = request.clone({
//     url: this._employeeJsonPath,
//   });
//   return next.handle(request).pipe(delay(500));
// }

// if (url.endsWith('/employes') && method === 'POST') {
//   const { body } = request.clone();
//   // assign a new uuid to new employee
//   body.id = uuidv4();
//   return of(new HttpResponse({ status: 200, body })).pipe(delay(500));
// }

// if (url.match(/\/employes\/.*/) && method === 'DELETE') {
//   const empId = this.getEmployeeId(url);
//   return of(new HttpResponse({ status: 200, body: empId })).pipe(
//     delay(500)
//   );
// }
